<template>
    <div id="mnemonic" class="has_fixed_bottom_box">
        <div class="container">
            <p class="warnning_txt_box">다음 12단어의 니모닉코드는 지갑을 복원하는데 사용됩니다. 반드시 타인이 볼 수 없는 안전한 장소에 보관해주십시오. 유출 시 고객자산의 피해로 이어질 수 있습니다.</p>
            <ul class="border inline rounded p-3 small flex mnemonic_list">
                <li v-for="(item, index) in code" :key="index">{{item}}</li>
            </ul>
            <div class="fixed_bottom_box p-3"><button type="button" class="btn btn-block btn-primary" @click="$router.push({name:'mnemonic-confirm'})"><small>다음</small></button></div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return{
            title: '니모닉 코드',
            lang: this.$route.params.lang,
            code:['code1','apple','brand','oldnew','hello','printf','jadam','sancheck','gift','yerin','menu','skill']
        }
    },
    
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
    },
}
</script>
<style scoped>
.mnemonic_list{
    flex-wrap: wrap;
    gap: 10px;
}
</style>