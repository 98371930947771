<template>
    <div id="mnemonic" class="has_fixed_bottom_box">
        <div class="container">
            <p class="warnning_txt_box">순서에 맞는 니모닉 코드를 올바르게 입력해주십시오.</p>
            <div class="mnemonic_wrap">
                <div class="form-group inline">
                    <label for="code1" class="mb-1">{{code[0]}}번째 단어를 입력해주세요.</label>
                    <input type="text" class="form-control" id="code1">
                </div>
                <div class="form-group inline">
                    <label for="code1" class="mb-1">{{code[1]}}번째 단어를 입력해주세요.</label>
                    <input type="text" class="form-control" id="code1">
                </div>
                <div class="form-group inline">
                    <label for="code1" class="mb-1">{{code[2]}}번째 단어를 입력해주세요.</label>
                    <input type="text" class="form-control" id="code1">
                </div>
            </div>
            <div class="fixed_bottom_box p-3"><button type="button" class="btn btn-block btn-primary"><small>제출</small></button></div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return{
            title: '니모닉 코드',
            lang: this.$route.params.lang,
            code:[],
        }
    },
    
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        for(var i=0; i<3; i++){
            let randomNum = null;
            randomNum = Math.floor(Math.random()*12+1)
            if(this.code.indexOf(randomNum) === -1){
                this.code.push(randomNum)
            }else{
                i--
            }
        }
    },
}
</script>
<style scoped>
.mnemonic_wrap .form-group{
    flex-wrap: wrap;
}
</style>